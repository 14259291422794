<script>
import FormControl from '../components/FormControl.vue'
import Loader from '../components/Loader.vue'
import {mapMutations} from 'vuex'

export default {
	components: {
		FormControl,
		Loader
	},

	data() {
		return {
			form: {
				user_number: '',
				password: ''
			},
			loading: false,
			loadingMessage: 'Verificando credenciales...',
			failLogin: false
		}
	},

	methods: {
		async handlerSubmit() {
			const vm = this
			vm.loading = true
			vm.loadingMessage = 'Verificando credenciales...'
			try {
				let data = await vm.$http.auth.login(this.form)
				vm.setSession(data)
				vm.loadingMessage = `Hola ${data.user.first_name ? data.user.first_name :  data.user.name }, preparando cuenta...`
				setTimeout(() => {
					vm.loading = false
					vm.$router.push({name: 'Home'})
				}, 3000);

			} catch (error) {
				
				vm.loading = false
				vm.failLogin = true
			}
		},

		...mapMutations({
			setSession: 'auth/setSession'
		})
	},

	mounted() {
		setTimeout(() => {
			document.querySelector('input').dispatchEvent(new Event('focus'))
		}, 1000);
	}
}
</script>
<template>
	<div class="login">
		
		<div class="login__content col-md-6 col-lg-5 col-xl-4 me-lg-auto px-3">
			<div class="mb-auto py-5 px-5 login__wrapper">
				<img class="login__logo" src="/img/logo-color.png" alt="">
			</div>
			<div class="login__wrapper">
				<div class="px-5">
					<p class="subtitle mb-0">Iniciar sesión</p>
					<h1 class="title mb-0">Insumos estrategicos</h1>
					<p v-if="failLogin" class="text-danger">Credenciales incorrectas</p>
					<div v-if="loading">
						<loader>
							<p>{{loadingMessage}}</p>
						</loader>
					</div>
					<form v-else @submit.prevent="handlerSubmit" class="m-0 mt-4">
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-12 p-2">
								<FormControl
								class="mb-3"
								:autocomplete="true"
								label="Centro de costos o Número de empleado"
								name="username"
								v-model="form.user_number"
								@input="failLogin = false" />
							</div>
							<div class="col-12 p-2">
								<FormControl
								class="mb-3"
								:autocomplete="true"
								label="Contraseña"
								name="password"
								type="password"
								v-model="form.password"
								@input="failLogin = false" />
							</div>
							<div class="col-12 p-2">
								<button type="submit" class="btn w-100 btn-complementary">Iniciar</button>
							</div>

							<div class="mt-3 text-center col-12 subtitle">
								<p class="text-center m-0 small">¿Tienes problemas para ingresar?</p>
								<p class="m-0 small">Envia un correo a</p>
								<p><a href="mailto:ayuda@profuturocompras.com.mx">ayuda@profuturocompras.com.mx</a></p>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div class="mt-auto">

			</div>
			<div class="mt-auto">

			</div>
		</div>
	</div>
</template>